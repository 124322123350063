@import "sanitize.css";

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  color: var(--cl-darkGrey);
  font-family: $primaryFontStack;
  font-size: 1.6rem;
  line-height: 1.2;
  min-height: 100%;
}

#__next {
  display: contents;
}

img {
  max-width: 100%;
}

figure {
  margin: 0;
}

*,
::before,
::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: relative;
  scroll-margin-top: var(--cl-headerHeight);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font: inherit;
  margin: 0 0 0.5em;
}

p {
  @extend %bodyLarge;
  color: inherit;
  margin-top: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

svg {
  fill: currentColor;
  height: 1em;
  width: 1em;

  &[role="img"] {
    height: auto;
    width: auto;
  }
}

button {
  background: transparent;
  border: none;
  color: inherit;
  padding: unset;
}

address {
  font: inherit;
}

small {
  font: inherit;
}

// Remove bullets added by Sanitize
nav li::before {
  display: none;
}

button,
input,
textarea,
select {
  font: inherit;
}

button,
[type="button"],
[type="reset"],
[type="submit"],
[type="file"] {
  cursor: pointer;
}

input,
textarea,
select {
  width: 100%;

  // Remove browser's autofill UI styling
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill::first-line {
    @extend %bodyLarge;
    transition: background-color 1000s 1000s;
  }
}

select {
  $arrow: svg-encode(
    '<svg fill="#{map-get($colors, "mediumRed")}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m7 10 5 5 5-5H7z"/></svg>'
  );
  appearance: none;
  background: url($arrow) no-repeat right 1rem center / 2.5rem;
  padding-right: 4rem;
}

[type="checkbox"],
[type="radio"] {
  @include visually-hidden;

  + label {
    align-items: center;
    cursor: pointer;
    display: grid;
    flex: 1;
    gap: 1rem;
    grid-auto-columns: auto 1fr;
    grid-auto-flow: column;

    &::before {
      content: "";
      font-size: 2rem;
      height: 1em;
      transition: all $transitionCubic;
      width: 1em;
    }
  }
}

[type="checkbox"] {
  + label::before {
    --borderColor: var(--cl-canadaLifeGrey);

    $tick: svg-encode(
      '<svg fill="#{map-get($colors, "mediumRed")}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>'
    );
    background: var(--cl-white) url($tick) no-repeat center 2rem / 1.3rem;
    border: 1px solid var(--borderColor);
    border-radius: 2px;
  }

  &:checked + label::before {
    background-position-y: center;
  }

  &:checked:focus + label::before {
    --borderColor: transparent;
  }
}

[type="radio"] {
  + label::before {
    --borderColor: var(--cl-canadaLifeGrey);
    --bgColor: var(--cl-white);
    --circleColor: var(--cl-canadaLifeRed);

    background: var(--circleColor);
    border-radius: 50%;
    box-shadow: inset 0 0 0 16px var(--bgColor), 0 0 0 1px var(--borderColor);
  }

  &:checked + label::before {
    box-shadow: inset 0 0 0 5px var(--bgColor), 0 0 0 1px var(--borderColor);
  }
}

::selection {
  background-color: var(--cl-canadaLifeRed);
  color: var(--cl-white);
}

hr {
  background: var(--cl-lightGrey);
  border: 0;
  height: 1px;
  margin: 0;
  padding: 0;
}

code {
  background: #f7fafc;
  border: 1px solid #e3e8ee;
  border-radius: 4px;
  color: var(--cl-darkGrey);
  display: inline-block;
  font-family: "Menlo", "Consolas", monospace;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 0.1rem 0.2rem;
}
