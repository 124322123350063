/* prettier-ignore */
@font-face {
  font-display: fallback;
  font-family: 'Calibre';
  font-weight: bold;
  src:  local('Calibre-Bold'),
        local('Calibre Bold'),
        url('/fonts/subset-Calibre-Bold.woff2')
        format('woff2');
}

/* prettier-ignore */
@font-face {
  font-display: fallback;
  font-family: "Calibre";
  font-weight: 500;
  src:  local('Calibre-Medium'),
        local('Calibre Medium'),
        url('/fonts/subset-Calibre-Medium.woff2')
        format('woff2');
}

/* prettier-ignore */
@font-face {
  font-display: fallback;
  font-family: "Calibre";
  font-weight: 600;
  src:  local('Calibre-Semibold'),
        local('Calibre Semibold'),
        url('/fonts/subset-Calibre-Semibold.woff2')
        format('woff2');
}

/* prettier-ignore */
@font-face {
  font-display: fallback;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  src:  local('Source Sans Pro SemiBold'),
        local('SourceSansPro-SemiBold'),
        url('/fonts/subset-SourceSansPro-SemiBold.woff2')
        format('woff2');
}

/* prettier-ignore */
@font-face {
  font-display: fallback;
  font-family: 'Source Sans Pro';
  font-weight: normal;
  src:  local('Source Sans Pro Regular'),
        local('SourceSansPro-Regular'),
        url('/fonts/subset-SourceSansPro-Regular.woff2')
        format('woff2');
}

/* prettier-ignore */
@font-face {
  font-display: fallback;
  font-family: 'Source Sans Pro';
  font-weight: bold;
  src:  local('Source Sans Pro Bold'),
        local('SourceSansPro-Bold'),
        url('/fonts/subset-SourceSansPro-Bold.woff2')
        format('woff2');
}
