%headerBase {
  font-family: $secondaryFontStack;
  font-weight: 600;
  letter-spacing: -0.2px;
}

%bodyBase {
  font-family: $primaryFontStack;
  font-weight: normal;
  letter-spacing: 0.5px;
}

%buttonBase {
  font-family: $secondaryFontStack;
  font-weight: 600;
  letter-spacing: 0.2px;
}

%bodyLarge {
  @extend %bodyBase;
  font-size: 1.6rem;
}

%bodyMedium {
  @extend %bodyBase;
  font-size: 1.4rem;
}

%bodySmall {
  @extend %bodyBase;
  font-size: 1.2rem;
}

%h1 {
  @extend %headerBase;
  font-weight: 700;
  @include clamp(font-size, 3.2rem, clamp-vw-value(4.4rem), 4.4rem);
}

%h2 {
  @extend %headerBase;
  font-size: 3.2rem;
  font-weight: 700;
}

%h3 {
  @extend %headerBase;
  font-size: 2.2rem;
}

%h4 {
  @extend %headerBase;
  font-size: 2rem;
}

%h5 {
  @extend %headerBase;
  font-size: 1.8rem;
}

%h6 {
  @extend %headerBase;
  font-size: 1.6rem;
}

%buttonLarge {
  @extend %buttonBase;
  font-size: 1.8rem;
}

%buttonMedium {
  @extend %buttonBase;
  font-size: 1.6rem;
}

%buttonSmall {
  @extend %buttonBase;
  font-size: 1.4rem;
}

%textLink {
  @include text-link;
}

%formLabel {
  font-family: $primaryFontStack;
  font-size: 1.6rem;
  font-weight: 600;
}

%helperText {
  font-family: $primaryFontStack;
  font-size: 1.4rem;
  font-weight: normal;
}
