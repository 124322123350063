.u-visuallyHidden {
  @include visually-hidden;
}

.u-scrollLocked {
  overflow: hidden;
}

.u-nowrap {
  white-space: nowrap;
}
