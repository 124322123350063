@use "sass:math";

@function strip-units($number) {
  @return math.div($number, $number * 0 + 1);
}

@function clamp-vw-value($max, $container: $containerMaxWidth) {
  // Calculation (example: 27.7777777778)
  $calc: math.div(strip-units($max), strip-units($container)) * 1000;

  // Rounded value (example: 2.7)
  $rounded: round($calc) * 0.1;

  @return #{$rounded}vw;
}

/// Provide an opinionated fallback for browsers that do not support clamp().
/// @group tools
/// @param {String} $property The property to apply clamp value to
/// @param {Number} $min Minimum value
/// @param {Number} $val Preferred value
/// @param {Number} $max Maximum allowed value
/// @requires {Function} mq Sass MQ mixin [https://github.com/sass-mq/sass-mq]
@mixin clamp($property, $min, $val, $max) {
  @if (not $property) {
    @error 'Please define the property you want to apply clamp to';
  }

  @if (not $min) or (not $val) or (not $max) {
    @error 'Please provide values for all three value parameters';
  }

  @supports ($property: clamp(1px, 1px, 1px)) {
    #{$property}: clamp(#{$min}, #{$val}, #{$max});
  }

  @supports not ($property: clamp(1px, 1px, 1px)) {
    #{$property}: #{$min};

    @include mq(m) {
      #{$property}: #{$val};
    }

    @include mq(xl) {
      #{$property}: #{$max};
    }
  }
}
