/**
 * -----------------------------
 * Table of Contents
 * -----------------------------
 *   1. General
 *   2. Breakpoints
 *   3. Colours
 *   4. Fonts
 *   5. Spacing
 *   6. Transitions
 *   7. z-index
 */

/**
 * 1. General
 */
$borderRadius: 2px;

/**
 * 2. Breakpoints
 */
@import "sass-mq/mq";

// prettier-ignore
$mq-breakpoints: (
  "xs":   320px,
  "s":    375px,
  "m":    768px,
  "l":    1024px,
  "xl":   1200px,

  // Tweakpoints
  ">s":   376px,
);

/**
 * 3. Colours
 */
// prettier-ignore
$colors: (
  // Core colours
  "canadaLifeRed":  #ba0c2f,
  "canadaLifeGrey": #838271,
  "black":          #1a1a1a,
  "white":          #ffffff,

  // Supporting colours
  "mediumRed":      #9d2235,
  "darkRed":        #862633,
  "darkGrey":       #51534a,
  "midGrey":        rgba(130, 130, 130, 0.25),
  "lightGrey":      #f0f0ef,
  "amDeepBlue":     #142f3a,
  "errorRed":       #ec0702,
  "warningOrange":  #ef9114,

  // Additional deeps
  "deepGold":       #b48b25,
  "deepGreen":      #497d3b,
  "deepTeal":       #007e7f,
  "deepBlue":       #007cbf,
  "deepPurple":     #5f5aa2,

  // Additional brights
  "brightGold":     #f3c716,
  "brightGreen":    #a6ce39,
  "brightTeal":     #4cc0ad,
  "brightBlue":     #00bdf2,
  "brightPurple":   #bd7cb5,

  // Additional transparent deeps
  "transparentTeal": #cce5e5,
  "transparentBlue": #cce4f2,
  "transparentCanadaLifeRed": #f1ced5,
);

/**
 * 4. Fonts
 */
$primaryFontStack: "Source Sans Pro", -apple-system, BlinkMacSystemFont,
  Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
  Helvetica Neue, sans-serif;

$secondaryFontStack: "Calibre", -apple-system, BlinkMacSystemFont, Segoe UI,
  Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
  sans-serif;

/**
 * 5. Spacing
 */
$spacing: (
  "xs": 0.5rem,
  "s": 1rem,
  "m": 2rem,
  "l": 3rem,
  "xl": 6rem,
  "xxl": 12rem,
);

$containerMaxWidth: 116.4rem;
$gutter: map-get($spacing, "l");

/**
 * 6. Transitions
 */
$transitionTiming: 0.3s;
$transitionTimingSlow: 0.5s;
$transitionCubicEasing: cubic-bezier(0.13, 0, 0.11, 1);
$transitionEasing: ease-out;

// Combine timing & easings into single easy use variables
$transitionCubic: $transitionTimingSlow $transitionCubicEasing;
$transition: $transitionTiming $transitionEasing;

/**
 * 7. z-index
 */
$zindex: (
  "main": 1,
  "dropdownPanel": 5,
  "headerBottom": 10,
  "headerTop": 11,
  "overlay": 20,
  "breadcrumbs": 30,
  "modal": 40,
);
