:root {
  @each $size, $value in $spacing {
    // example output: --cl-space-m: 2rem;
    --cl-space-#{$size}: #{$value};
  }

  --cl-containerMaxWidth: #{$containerMaxWidth};
  --cl-gutter: var(--cl-space-l);
  --cl-containerGutter: var(--cl-space-m);
  --cl-headerHeight: 12.5rem;
  --cl-headerMastHeight: 3.3rem;

  @each $name, $value in $colors {
    --cl-#{$name}: #{$value};
  }
}
