@mixin text-link($textColor: var(--cl-canadaLifeRed)) {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 0 1px;
  color: $textColor;

  font-weight: 600;
  outline: none;
  transition: background-size $transitionCubic;

  // Animate underline for hover & focus across all devices
  &:hover,
  &:focus,
  &:focus-within {
    background-position: 0 100%;
    background-size: 100% 1px;
  }

  // Add focus styles
  &:focus,
  &:focus-within {
    background-color: var(--cl-brightGold);
    box-shadow: 0 0 0 0.1em var(--cl-brightGold);
    color: var(--cl-black);
  }

  // Undo focus styles for mouse/pointer interaction
  &:focus:not(:focus-visible) {
    background-color: inherit;
    box-shadow: none;
    color: $textColor;
  }

  &.isDisabled,
  &[disabled] {
    color: var(--cl-canadaLifeGrey);
    cursor: not-allowed;
  }
}
